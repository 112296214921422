<template>
  <div class="holder">
    <h1>
      折线双Y轴专用图表
      <span @click="playInTime('doubleYLinesChart')">入场</span>
      <span @click="playOut('doubleYLinesChart')">出场</span>
    </h1>
    <double-y-lines-chart
      ref="doubleYLinesChart"
      id="doubleYLinesChart"
      customClass="c_line1"
    />
  </div>
</template>
<script>
import doubleYLinesChart from "@/components/marketChart/doubleYLinesChart.vue";
import linesDoubleY from "./linesDoubleY.json";
export default {
  data: function () {
    return {};
  },
  components: { doubleYLinesChart },
  methods: {
    playInTime(refName) {
      this.$refs[refName].doubleYLinesChart(linesDoubleY, {
        width: 600,
        height: 300,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 80,
        duration: 1000,
        delay: 40,
        ease: "easeCubicInOut",
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.c_line1,
.c_line11 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #fff;
  // background: -webkit-linear-gradient(top right, #e5884f, #e16906);
  // background: -moz-linear-gradient(top right, #e5884f, #e16906);
  // background: linear-gradient(to bottom left, #e5884f, #e16906);
  display: inline-block;
  /deep/ .label_min {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_min_bg {
    fill: #e24c4c;
  }
  /deep/ .circle_min {
    fill: rgba(226, 76, 76, 0.692);
  }
  /deep/ .label_max {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_max_bg {
    fill: #0b777a;
  }
  /deep/ .circle_max {
    fill: rgba(11, 118, 122, 0.712);
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #fff;
    }
    .tick_long_line {
      display: none;
    }
  }
  /deep/ .line1{
    stroke:  #178CEA;
  }
  /deep/ .area_top {
    stop-color: #178CEA;
    stop-opacity: 0.42;
  }
  /deep/ .area_bottom {
    stop-color: #178CEA;
    stop-opacity: 0.26;
  }
}

.c_line2 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #bfe54f;
  background: -webkit-linear-gradient(top right, #bfe54f, #a3a829);
  background: -moz-linear-gradient(top right, #bfe54f, #a3a829);
  background: linear-gradient(to bottom left, #bfe54f, #a3a829);
  display: inline-block;
  /deep/ .label_min {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_min_bg {
    fill: #e24c4c;
  }
  /deep/ .circle_min {
    fill: rgba(226, 76, 76, 0.692);
  }
  /deep/ .label_max {
    font-size: 14px;
    fill: #fff;
  }
  /deep/ .label_max_bg {
    fill: #0b777a;
  }
  /deep/ .circle_max {
    fill: rgba(11, 118, 122, 0.712);
  }
  /deep/ .line1 {
    stroke: #fefefe;
    stroke-width: 3px;
  }
  /deep/ .line2 {
    stroke: #81195c;
    stroke-width: 3px;
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #fff;
    }
    .tick_line {
      display: none;
    }
    .tick_long_line {
      stroke: rgba(255, 255, 255, 0.15);
    }
  }
}

.c_line3 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #4fd8e5;
  background: -webkit-linear-gradient(top right, #4fd8e5, #297aa8);
  background: -moz-linear-gradient(top right, #4fd8e5, #297aa8);
  background: linear-gradient(to bottom left, #4fd8e5, #297aa8);
  display: inline-block;
  /deep/ .line1 {
    stroke: #fefefe;
    stroke-width: 3px;
  }
  /deep/ .line2 {
    stroke: #81195c;
    stroke-width: 3px;
  }
  /deep/ .axis_x,
  /deep/ .axis_y {
    .text {
      fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      display: none;
    }
  }
}

.c_line11 {
  /deep/ .circle1 {
    fill: #fefefe;
  }
  /deep/ .valueTip1 {
    fill: #fefefe;
  }
  /deep/ .circle2 {
    fill: #81195c;
  }
  /deep/ .valueTip2 {
    fill: #81195c;
  }
  /deep/ .circle3 {
    fill: red;
  }
  /deep/ .valueTip3 {
    fill: red;
  }
  /deep/ .line3 {
    stroke: red;
    stroke-width: 3px;
  }
}
.c_line12 {
  margin-bottom: 20px;
  min-height: 200px;
}
</style>
